@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
}
.fade {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: infinite;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
