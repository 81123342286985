.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(70, 195, 238, 0.5);
  /* animation-fill-mode: forwards; */
}
.fade {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: infinite;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sphere-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.sphere {
  border-radius: 50%;
  height: 60px;
  margin: 0 10px;
  width: 60px;
}

.sphere1 {
  animation: sphere 1.2s 0.2s ease-in-out infinite;
}
.sphere2 {
  animation: sphere 1.2s 0.5s ease-in-out infinite;
}
.sphere3 {
  animation: sphere 1.2s 0.8s ease-in-out infinite;
}

@keyframes sphere {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: translate(0, 50px);
  }
  100% {
    transform: scale(1.3);
  }
}
