.ant-select {
  height: 64px;
  border-radius: 24px;
}
.ant-select .ant-select-selection-item {
  font-size: 24px;
  letter-spacing: 0.2em;
  font-weight: 700;
}
.customSelect {
  width: 432px !important;
  inset: 231px auto auto 550px !important;
}
.firstHeader {
  width: 320px  !important;
  inset: 90px auto auto 10px !important;
}
.singleTeam {
  width: 350px !important;
  inset: 80px 60px auto auto !important;
}
.lastHeader {
  width: 310px !important;
  inset: 90px 15px auto auto !important;
}

.text-black .ant-select-selection-item {
  color: black;
}
.text-white .ant-select-selection-item {
  color: white;
}
